import { createSlice } from "@reduxjs/toolkit";
import { IBrand } from "../interfaces/IBrand";
import { ICategory } from "../interfaces/ICategory";
import { IDistributor } from "../interfaces/IDistributor";
import { IGeneral } from "../interfaces/IGeneral";
import { IItemType } from "../interfaces/IItemType";
import { IManufacturer } from "../interfaces/IManufacturer";
import { ISupplier } from "../interfaces/ISupplier";

const initialState: IGeneral = {
  types: [],
  basePriceTypes: [],
  categoryTypes: [],
  fieldTypes: [],
  groupPriceStatus: [],
  itemImageTypes: [],
  itemStatus: [],
  numberValueLabels: [],
  perTypes: [],
  priceChangeTypes: [],
  priceEndTypes: [],
  returnTypes: [],
  unitConvertTypes: [],
  unitSpaceLabels: [],
  unitWeightLabels: [],
  specialPriceStatus: [],
  brands: [],
  manufacturers: [],
  countries: [],
  distributors: [],
  tags: [],
  categories: [],
  suppliers: [],
  itemTypes: [],
  bankNames: [],

  businessStaffRoles: [],
  businessStatus: [],
  businessTypes: [],
  currencies: [],
  equityTypes: [],
  legalEntityTypes: [],
  partnerClassifications: [],
  partnerRegisterStatus: [],
  partnerTypes: [],
  paymentMethod: [],
  transactionTypes: [],
  inOutTypes: [],
  transactionStatus: [],
  permissions: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    initGeneral: (state, { payload }: { payload: IGeneral }) => {
      const {
        types,
        basePriceTypes,

        categoryTypes,

        fieldTypes,
        groupPriceStatus,
        itemImageTypes,
        itemStatus,
        numberValueLabels,
        perTypes,
        priceChangeTypes,
        priceEndTypes,
        returnTypes,
        unitConvertTypes,
        unitSpaceLabels,
        unitWeightLabels,
        specialPriceStatus,
        brands,
        manufacturers,
        countries,
        distributors,
        tags,
        categories,
        suppliers,
        itemTypes,
        bankNames,

        businessStaffRoles,
        businessStatus,
        businessTypes,
        currencies,
        equityTypes,
        legalEntityTypes,
        partnerClassifications,
        partnerRegisterStatus,
        partnerTypes,
        paymentMethod,
        transactionTypes,
        inOutTypes,
        transactionStatus,
        permissions,
      } = payload;

      state.types = types;
      state.basePriceTypes = basePriceTypes;
      state.businessTypes = businessTypes;
      state.categoryTypes = categoryTypes;
      state.currencies = currencies;
      state.fieldTypes = fieldTypes;
      state.groupPriceStatus = groupPriceStatus;
      state.itemImageTypes = itemImageTypes;
      state.itemStatus = itemStatus;
      state.numberValueLabels = numberValueLabels;
      state.perTypes = perTypes;
      state.priceChangeTypes = priceChangeTypes;
      state.priceEndTypes = priceEndTypes;
      state.returnTypes = returnTypes;
      state.unitConvertTypes = unitConvertTypes;
      state.unitSpaceLabels = unitSpaceLabels;
      state.unitWeightLabels = unitWeightLabels;
      state.specialPriceStatus = specialPriceStatus;

      state.brands = brands;
      state.manufacturers = manufacturers;
      state.countries = countries;
      state.distributors = distributors;
      state.tags = tags;
      state.categories = categories;
      state.suppliers = suppliers;
      state.itemTypes = itemTypes;

      state.bankNames = bankNames;
      state.businessStaffRoles = businessStaffRoles;
      state.businessStatus = businessStatus;
      state.businessTypes = businessTypes;
      state.currencies = currencies;
      state.equityTypes = equityTypes;
      state.legalEntityTypes = legalEntityTypes;
      state.partnerClassifications = partnerClassifications;
      state.partnerRegisterStatus = partnerRegisterStatus;
      state.partnerTypes = partnerTypes;
      state.paymentMethod = paymentMethod;
      state.transactionTypes = transactionTypes;
      state.inOutTypes = inOutTypes;
      state.transactionStatus = transactionStatus;
      state.permissions = permissions;
      return state;
    },
    updateBrands: (state, { payload }: { payload: IBrand[] }) => {
      state.brands = payload;

      return state;
    },
    updateSuppliers: (state, { payload }: { payload: ISupplier[] }) => {
      state.suppliers = payload;

      return state;
    },
    updateManufacturers: (state, { payload }: { payload: IManufacturer[] }) => {
      state.manufacturers = payload;

      return state;
    },
    updateDistributors: (state, { payload }: { payload: IDistributor[] }) => {
      state.distributors = payload;

      return state;
    },
    updateItemTypes: (state, { payload }: { payload: IItemType[] }) => {
      state.itemTypes = payload;

      return state;
    },
    updateCategories: (state, { payload }: { payload: ICategory[] }) => {
      state.categories = payload;

      return state;
    },
  },
});

export const generalReducer = generalSlice.reducer;

export const { initGeneral, updateBrands, updateSuppliers, updateManufacturers, updateDistributors, updateItemTypes, updateCategories } = generalSlice.actions;
