import React, { FormEvent, useContext, useImperativeHandle } from "react";

type ValueProps = {
  [key: string]: any;
};

type IForm = {
  errors: ValueProps;
  setErrors: (errors: ValueProps) => void;
  values: ValueProps;
  setItems: (name: string, values: any) => void;
  addItem: (name: string, value: any) => void;
  removeItem: (name: string, index: number) => void;
  handleChange: (name: string) => (value: any) => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement>) => Promise<boolean>;
  setFieldValue: (name: string, value: any) => void;
};

const FormConfig: IForm = {
  errors: {},
  setErrors: () => () => null,
  values: {},
  handleChange: () => () => null,
  setItems: () => () => null,
  removeItem: () => () => null,
  addItem: () => () => null,
  handleSubmit: (_e?: FormEvent<HTMLFormElement>) => Promise.resolve(true),
  setFieldValue: () => null,
};

const FormContext = React.createContext<IForm>(FormConfig);

type Props = {
  initialValues: ValueProps;
  validationSchema?: any;
  onSubmit?: (values: any) => void;
  children: (props: IForm) => JSX.Element;
};

const deserializeObject = (payload: any, fieldName: string, value: any) => {
  return Object.keys(payload || {}).reduce<any>((accumulator, key) => {
    let reduces = accumulator;

    if (Array.isArray(payload[key])) {
      reduces = (payload[key] as any[]).reduce<any>((accumulator, iterator, index) => {
        let reduces = accumulator as any;

        if (typeof iterator === "object") {
          reduces = deserializeObject(iterator, key + `[${index}].`, value);
        } else if (iterator?.length > 0) {
          if (!reduces[fieldName + `${key}`]) reduces[fieldName + `${key}`] = [];

          reduces[fieldName + `${key}`][`${index}`] = iterator;
        } else {
          reduces[fieldName + `${key}[${index}]`] = iterator;
        }

        return reduces;
      }, reduces);
    } else if (typeof payload[key] === "object") {
      reduces = deserializeObject(payload[key], fieldName + key + ".", value);
    } else {
      reduces[fieldName + key] = payload[key];
    }

    return reduces;
  }, value);
};

const deserializeData = (data: any) => {
  return deserializeObject(data, "", {});
};

const serializeObject = (fields: string[], payload: any, value: any) => {
  const array = fields;
  const reduces = payload;

  const key = array.shift();
  if (!key) return value || reduces;

  if (key.indexOf("[") !== -1) {
    const field = key.split("[")[0];

    if (!field) return reduces;

    const index = parseInt(`${key.split("[")[1]?.replace("]", "")}`, 10);

    if (!reduces[field]) reduces[field] = [];
    if (!reduces[field][index]) reduces[field][index] = {};

    reduces[field][index] = serializeObject(array, reduces[field][index], value);

    return reduces;
  }

  if (array.length > 0) {
    reduces[key] = serializeObject(array, reduces[key] || {}, value);
  } else {
    reduces[key] = value;
  }

  return reduces;
};

const serializeData = (data: any) => {
  return Object.keys(data || {}).reduce<any>((accumulator, fieldName) => {
    let reduces = accumulator;
    const array = fieldName.split(".");

    reduces = serializeObject(array, reduces, data[fieldName]);

    return reduces;
  }, {});
};

export const useArrayField = (name: string) => {
  const { values, errors, setItems, addItem, removeItem } = useContext(FormContext);

  return {
    items: serializeData(values)[name] || [],
    error: errors[name],
    setItems: (items: any[]) => setItems(name, items),
    addItem: (value: any) => addItem(name, value),
    removeItem: (index: number) => removeItem(name, index),
  };
};

export const useField = (name: string) => {
  const { errors, values, handleChange, handleSubmit, setFieldValue } = useContext(FormContext);

  return {
    error: errors[name],
    value: values[name] || "",
    onChange: handleChange(name),
    onSubmit: handleSubmit,
    getValue: (name: string) => serializeData(values)[name],
    setValue: (value: any) => setFieldValue(name, value),
  };
};

type IField = {
  name: string;
  children: ({ value, onChange, error }: any) => JSX.Element;
};

export const Field = ({ name, children }: IField) => {
  const { value, onChange, error } = useField(name);

  return children({ value, onChange, error });
};

export type IFormRef<T> = {
  submit: () => any;
  validate: () => Promise<{ [key: string]: string }>;
  setFormData: (callback: (state: T) => T) => void;
};

const Form = React.forwardRef(({ initialValues, validationSchema, onSubmit, children }: Props, ref: React.Ref<IFormRef<any>>) => {
  const deserialize = deserializeData(initialValues);
  const [submitted, setSubmitted] = React.useState(false);
  const [values, setFormData] = React.useState(deserializeData(deserialize));
  const [touched, setTouched] = React.useState<{ [key: string]: any }>(
    Object.keys(deserializeData(deserialize)).reduce(
      (accumulator, iterator) => ({
        ...accumulator,
        [iterator]: false,
      }),
      {},
    ),
  );
  const [errors, setErrors] = React.useState({});

  useImperativeHandle(ref, () => ({
    submit() {
      return handleSubmit();
    },
    validate() {
      return new Promise((resolve) => {
        setSubmitted(true);

        const touched = Object.keys(values || {}).reduce(
          (accumulator, iterator) => ({
            ...accumulator,
            [iterator]: true,
          }),
          Object.keys(initialValues || {}).reduce((accumulator, iterator) => {
            return {
              ...accumulator,
              [iterator]: true,
            };
          }, {}),
        );

        setTouched(touched);

        validate(values, touched, (errors) => {
          setErrors(errors);

          resolve(errors || {});
        });
      });
    },
    setFormData: (callback: (state: typeof initialValues) => typeof initialValues) => {
      let data = serializeData(values);

      const result = callback(data);

      result && setFormData(deserializeData(result));
    },
  }));

  const handleChange = (name: string) => (value: any) => {
    const data = { ...values, [name]: value };

    if (touched[name] === true)
      validate(data, touched, (errors) => {
        setErrors(errors);
      });

    setTouched((state) => ({ ...state, [name]: true }));
    setFormData(data);
  };

  const validate = (values: typeof initialValues, toucheds: typeof touched, callback: (errors: any) => void) => {
    const data = serializeData(values);
    validationSchema
      .validate(data, { abortEarly: false })
      .then((isValid: boolean) => {
        if (isValid) callback({});
      })
      .catch((err: any) => {
        console.log("error: ", err);
        const errors = err.inner.reduce((errors: any, currentError: any) => {
          if (!toucheds[currentError.path]) return errors;

          return {
            ...errors,
            [currentError.path]: currentError.message,
          };
        }, {});
        callback(errors);
      });
  };

  const handleSubmit = (e?: FormEvent<HTMLFormElement>): Promise<any | null> => {
    e?.preventDefault();

    const data = serializeData(values);

    setSubmitted(true);

    const touched = Object.keys(values || {}).reduce(
      (accumulator, iterator) => ({
        ...accumulator,
        [iterator]: true,
      }),
      Object.keys(initialValues || {}).reduce((accumulator, iterator) => {
        return {
          ...accumulator,
          [iterator]: true,
        };
      }, {}),
    );

    setTouched(touched);

    return new Promise((resolve) => {
      if (validationSchema) {
        validate(values, touched, (errors) => {
          setErrors(errors);

          if (onSubmit && Object.keys(errors || {}).length === 0) {
            onSubmit(data);
          }

          if (Object.keys(errors || {}).length === 0) resolve(data);
          else resolve(null);
        });
      } else if (onSubmit) {
        onSubmit(data);
        resolve(data);
      }
    });
  };

  const setFieldValue = (name: string, value: any) => {
    setFormData((state: any) =>
      deserializeData({
        ...serializeData(state),
        [name]: value,
      }),
    );
  };

  const addItem = (name: string, value: any) => {
    const data = serializeData(values);

    if (!data[name]) data[name] = [];

    data[name].push(value);

    const deserialize = deserializeData(data);

    setFormData(deserialize);

    if (submitted && validationSchema) {
      const touched = Object.keys(deserialize).reduce(
        (accumulator, iterator) => ({
          ...accumulator,
          [iterator]: true,
        }),
        {},
      );

      setTouched(touched);

      validate(deserialize, touched, (errors) => {
        setErrors(errors);
      });
    }
  };

  const removeItem = (name: string, index: number) => {
    const data = serializeData(values);

    data[name] = data[name]?.filter((_i: any, i: number) => i !== index);

    const deserialize = deserializeData(data);

    setFormData(deserialize);

    if (submitted && validationSchema) {
      const touched = Object.keys(deserialize).reduce(
        (accumulator, iterator) => ({
          ...accumulator,
          [iterator]: true,
        }),
        {},
      );

      setTouched(touched);

      validate(deserialize, touched, (errors) => {
        setErrors(errors);
      });
    }
  };

  const setItems = (name: string, items: any[]) => {
    const data = serializeData(values);

    data[name] = items;

    const deserialize = deserializeData(data);

    setFormData(deserialize);

    if (submitted && validationSchema) {
      const touched = Object.keys(deserialize).reduce(
        (accumulator, iterator) => ({
          ...accumulator,
          [iterator]: true,
        }),
        {},
      );

      setTouched(touched);

      validate(deserialize, touched, (errors) => {
        setErrors(errors);
      });
    }
  };

  const setFormErrors = (errors: any) => {
    setErrors(errors);
  };

  return (
    <FormContext.Provider
      value={{
        errors,
        setErrors: setFormErrors,
        values,
        setItems,
        removeItem,
        addItem,
        setFieldValue,
        handleChange,
        handleSubmit,
      }}>
      <form onSubmit={handleSubmit} noValidate>
        {children({
          values: serializeData(values),
          setItems,
          removeItem,
          addItem,
          errors,
          setErrors: setFormErrors,
          setFieldValue,
          handleChange,
          handleSubmit,
        })}
      </form>
    </FormContext.Provider>
  );
});

export { Form };
