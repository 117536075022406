import { TextInput } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  noError?: boolean;
  rightSection?: React.ReactNode;
  classNames?: Partial<Record<string, string>> | undefined;
};

export function TextField({ name, label, noError = false, placeholder, disabled = false, rightSection, classNames }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      value={value}
      rightSection={rightSection}
      onChange={(e) => onChange(e.target.value)}
      classNames={classNames}
      styles={{
        error: {
          display: noError ? "none" : "block",
        },
        wrapper: {
          marginBottom: noError && error && 0,
        },
      }}
      required
    />
  );
}
