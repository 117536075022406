import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: { name: string; description: string; isGoods: boolean; isService: boolean }) => {
  return httpRequest.post("/pay/item_type", data);
};

export const select = async (data: { isGoods?: boolean; isService?: boolean }) => {
  return httpRequest.get("/pay/item_type/select", data);
};
