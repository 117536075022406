import { LoadingOverlay } from "@mantine/core";
import qs from "qs";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { AuthApi, GeneralApi } from "../../apis";
import { authChange, authMe } from "../../store/Auth";
import { initGeneral } from "../../store/General";
import { message } from "../../utils/Message";

export function Access() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      try {
        const params = qs.parse(location.search);
        dispatch(authChange({ accessToken: params["?token"] } as any));
        (async () => {
          try {
            const res = await GeneralApi.init();
            dispatch(initGeneral(res));
            const auth = await AuthApi.me();
            dispatch(authMe(auth));
            navigate("/");
          } catch (error: any) {
            message.error(error?.message);
            console.log(error);
          }
        })();
      } catch (err) {
        console.log(err);
      }

      navigate("/");
    })();
  }, [dispatch, location, navigate]);

  return <LoadingOverlay visible />;
}
