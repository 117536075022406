import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: { name: string; description: string; type: string; itemTypeId: string; parentId?: string }) => {
  return httpRequest.post("/pay/category", data);
};

export const select = async (data?: { type?: string; itemTypeId?: string }) => {
  return httpRequest.get("/pay/category/select", data);
};
