import { Select } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
  noError?: boolean;
  options: { value: string; label: string }[];
  clearable?: boolean;
  classNames?: Partial<Record<string, string>> | undefined;
  searchable?: boolean;
  onSearchChange?: any;
};

export function SelectField({
  name,
  label,
  placeholder,
  noError = false,
  onChange: onChangeValue,
  options = [],
  disabled = false,
  classNames,
  clearable = true,
  searchable,
  onSearchChange,
}: Props) {
  const { value, error, onChange } = useField(name);
  let temp = "";

  return (
    <Select
      label={label}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      searchable={searchable}
      onChange={(value) => {
        onChange(value);
        onChangeValue && onChangeValue(value);
      }}
      error={error}
      required
      clearable={clearable}
      data={options}
      style={{ flex: 1 }}
      classNames={classNames}
      onSearchChange={(e) => {
        if (searchable && e && temp !== e) {
          onSearchChange && onSearchChange(e);
          temp = e;
        }
      }}
      styles={{
        error: {
          display: noError ? "none" : "block",
        },
        wrapper: {
          marginBottom: noError && error && 0,
        },
      }}
    />
  );
}
