import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: { name: string }) => {
  return httpRequest.post("/pay/manufacturer", data);
};

export const select = async () => {
  return httpRequest.get("/pay/manufacturer/select");
};
