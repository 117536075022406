import { IFilter } from "../interfaces/IFilter";
import { IService } from "../interfaces/IService";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/pay/service", { offset, filter });
};

export const create = async (data: IService | any) => {
  return httpRequest.post("/pay/service", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/pay/service/${id}`);
};
