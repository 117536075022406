import { IFilter } from "../interfaces/IFilter";
import { IGroupPrice } from "../interfaces/IGroupPrice";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/pay/group_price", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/pay/group_price/${id}`);
};

export const select = async ({ query }: { query: string }) => {
  return httpRequest.get("/pay/group_price", { query });
};

export const create = async (data: IGroupPrice | any) => {
  return httpRequest.post("/pay/group_price", data);
};
