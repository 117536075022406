import { Alert } from "@mantine/core";
import { IconUserShield } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";

export type IPermissionCode = "PAY_DASH" | "PAY_ACC" | "PAY_TRX" | "PAY_STM";

export function usePermission(code: IPermissionCode) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { permissions } = useSelector((state: { general: IGeneral }) => state.general);

  function accessDenied() {
    return (
      <Alert title={code && "Хандах эрх хүрэлцэхгүй байна!"} icon={<IconUserShield />} color="red">
        {permissions?.find((item: any) => item.code === code)?.name || "Хандах эрх хүрэлцэхгүй байна!"}
      </Alert>
    );
  }

  const hasAccess = user?.permissions?.some((som: any) => som.code === code) || false;
  const isCreate = user?.permissions?.some((item: any) => item.code === code && item.isCreate) || false;
  const isDelete = user?.permissions?.some((item: any) => item.code === code && item.isDelete) || false;
  const isEdit = user?.permissions?.some((item: any) => item.code === code && item.isEdit) || false;
  const isReview = user?.permissions?.some((item: any) => item.code === code && item.isReview) || false;
  const isView = user?.permissions?.some((item: any) => item.code === code && item.isView) || false;

  const text = permissions?.find((item: any) => item.code === code)?.name || "Хандах эрх хүрэлцэхгүй байна!";

  return {
    hasAccess,
    isCreate,
    isDelete,
    isEdit,
    isReview,
    isView,
    accessDenied,
    text,
  };
}
