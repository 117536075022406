import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/pay/bank_account", { offset, filter });
};

export const create = async (data: formData) => {
  return httpRequest.post("/pay/bank_account", data);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/pay/bank_account/${id}`, data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/pay/bank_account/${id}`);
};

export const check = async (data: { bankName: string; number: string }) => {
  return httpRequest.post(`/pay/bank_account/check`, data);
};

export const pay = async (data: any) => {
  return httpRequest.post("/pay/invoice", data);
};

type formData = {
  bankName: string;
  number: string;
  isDefault: boolean;
  shortName: string;
};
