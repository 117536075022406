import { Avatar, Badge, Box, Button, Divider, Flex, Grid, Input, LoadingOverlay, Pagination, Paper, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import qs from "qs";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { transactionApi } from "../../apis";
import { AccountStatementTable } from "../../components/account-statement-table";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { ColumnType } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { message } from "../../utils/Message";

var today = new Date();

export const StatementDetails = () => {
  const breadcrumbs = useBreadcrumb();
  const { theme } = useStyle();
  const params = useParams();
  const location = useLocation();
  const tableRef = useRef<any>(null);
  const [filters, setFilter] = React.useState<any>({
    accountId: `${params.id}`,
    inOutType: "ALL",
    startDate: dayjs(today).subtract(14, "day").format(), // Use ISO format directly
    endDate: dayjs(today).format(),
  });
  const [dateRange, setDateRange] = useState<any>([dayjs(today).subtract(14, "day").toDate(), dayjs(today).toDate()]);
  const [offset, setOffset] = React.useState({ page: 1, limit: 10 });
  const navigate = useNavigate();
  const { paymentMethod, transactionTypes, currencies, transactionStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const PAY_STM = usePermission("PAY_STM");

  const { data, isLoading } = useSwr<any>(
    PAY_STM.hasAccess ? `table.${params.id}.?${qs.stringify({ ...filters })}` : null,
    async () => {
      if (PAY_STM.hasAccess) {
        let res = await transactionApi.account({
          filter: filters,
        });
        console.log("res: ", res);

        if (res?.hasUrl === false)
          return { count: res?.rows?.length || 0, rows: res?.rows || [], firstBalance: res.firstBalance, lastBalance: res.lastBalance };
        else if (res?.hasUrl === true) {
          message.error("нэвтрэх хүчинтэй хугацаа дууссан.");

          navigate("/statement");
        }
      }
    },
    {
      fallbackData: { count: 0, rows: [] },
    },
  );

  const transactionStatusTag = (val: string) => {
    switch (val) {
      case "FAILED": {
        return (
          <Badge color="red" size="md" radius="sm" variant="filled">
            {transactionStatus.find((item: IReference) => item.code === val)?.name}
          </Badge>
        );
      }
      case "PENDING": {
        return (
          <Badge color="gray" size="md" radius="sm" variant="filled">
            {transactionStatus.find((item: IReference) => item.code === val)?.name}
          </Badge>
        );
      }
      case "SUCCESS": {
        return (
          <Badge color="green" size="md" radius="sm" variant="filled">
            {transactionStatus.find((item: IReference) => item.code === val)?.name}
          </Badge>
        );
      }
      default: {
        return (
          <Badge color="blue" size="md" radius="sm" variant="filled">
            {val || "N/A"}
          </Badge>
        );
      }
    }
  };

  const columns = useHeader({
    transactionStatusTag,
    paymentMethod,
    transactionTypes,
    currencies,
    onClick: () => {},
  });

  const updateDateRange = () => {
    setFilter({
      ...filters,
      startDate: dateRange[0] ? dayjs(dateRange[0]).toISOString() : null,
      endDate: dateRange[1] ? dayjs(dateRange[1]).toISOString() : null,
    });
  };

  return (
    <PageLayout title="Дансны хуулга" subTitle="Сонгосон дансны хуулга" breadcrumb={breadcrumbs}>
      {PAY_STM.hasAccess ? (
        <>
          <Paper pos={"relative"}>
            <LoadingOverlay visible={isLoading} title="Түр хүлээнэ үү." />
            <Divider h="lg" />
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Сонгосон дансны дугаар">
                  <Input readOnly value={`${location?.state?.number || "-"}, ${location?.state?.name}`} placeholder="Данс, дугаар" />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6 }}>
                <Flex gap={8} align="flex-end">
                  <DatePickerInput
                    type="range"
                    w={320}
                    maxDate={new Date()}
                    value={dateRange}
                    onChange={(e) => {
                      setDateRange(e);
                    }}
                    label="Хуулга харах огноо:"
                    placeholder="Эхлэх - Дуусах "
                    leftSection={<IconCalendar size={16} />}
                    valueFormat="YYYY-MM-DD"
                  />
                  <Button disabled={!dateRange[0] || !dateRange[1]} onClick={updateDateRange}>
                    <Text>Хуулга харах</Text>
                  </Button>
                </Flex>
              </Grid.Col>
              <Grid.Col span={12}>
                <Paper>
                  <Grid>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Дансны дугаар">
                        <Text fw={500} c={theme.primaryColor}>
                          {location?.state?.number || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Дансны нэр">
                        <Text fw={500} c={theme.primaryColor}>
                          {location?.state?.name || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Банкны нэр">
                        <Text fw={500} c={theme.primaryColor}>
                          {location?.state?.bankName || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хуулга хамрах огноо">
                        <Text fw={500} c={theme.primaryColor}>
                          {dayjs(filters.startDate).format("YYYY-MM-DD")} - {dayjs(filters.endDate).format("YYYY-MM-DD")}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Эхний үлдэгдэл">
                        <Text fw={500} c={theme.primaryColor}>
                          {data?.count > 0 ? currencyFormat(data?.rows[data?.count - 1]?.firstAmount || 0) : "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Эцсийн үлдэгдэл">
                        <Text fw={500} c={theme.primaryColor}>
                          {currencyFormat(data.rows[0]?.lastAmount || 0)}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                  </Grid>
                </Paper>

                <Divider h={"lg"} mt="sm" />

                <AccountStatementTable
                  pagination={false}
                  ref={tableRef}
                  isShowDate={false}
                  name="account.statement.list"
                  columns={columns}
                  dataSource={
                    data?.rows
                      .map((item: any, index: number) => ({ ...item, index: (index = index + 1) }))
                      .filter((_item: any, index: number) => index < offset.limit * offset.page && index >= offset.limit * offset.page - offset.limit) || []
                  }
                />
                <Box mt={20} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Pagination
                    onChange={(e) => {
                      console.log(e);
                      setOffset({ ...offset, page: e });
                    }}
                    total={data.count / offset.limit + (data.count % offset.limit > 0 ? 1 : 0)}
                  />
                </Box>
              </Grid.Col>
            </Grid>
          </Paper>
        </>
      ) : (
        PAY_STM.accessDenied()
      )}
    </PageLayout>
  );
};

const useHeader = ({
  transactionStatusTag,
  paymentMethod,
  transactionTypes,
  currencies,
}: {
  onClick: (key: string, record: any) => void;
  paymentMethod: IReference[];
  transactionTypes: IReference[];
  transactionStatusTag: (key: string) => JSX.Element;
  currencies: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_record, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Огноо, цаг",
    width: "100px",
    render: (record) => (
      <Text w={"max-content"} size="sm">
        {dateTimeFormat(record?.tranDate)}
      </Text>
    ),
  },
  {
    title: "Лавлах код",
    sorter: true,
    render: (record) => <Text size="sm">{record?.tranId ?? "-"}</Text>,
  },
  {
    title: "Орлогын гүйлгээ",
    sorter: true,
    render: (record) => {
      if (record?.drOrCr === "Credit")
        return (
          <Text c="green" ta="right" size="sm">
            {currencyFormat(record?.tranAmount ?? 0)}
          </Text>
        );
      return (
        <Text ta="right" size="sm">
          -
        </Text>
      );
    },
  },
  {
    title: "Зарлагын гүйлгээ",
    sorter: true,
    render: (record) => {
      if (record?.drOrCr === "Debit")
        return (
          <Text c="red" ta="right" size="sm">
            {currencyFormat(record?.tranAmount ?? 0)}
          </Text>
        );
      return (
        <Text ta="right" size="sm">
          -
        </Text>
      );
    },
  },
  {
    title: "Эцсийн үлдэгдэл",
    sorter: true,
    render: (record) => (
      <Text ta="right" size="sm">
        {currencyFormat(parseFloat(record?.balance) ?? 0)}
      </Text>
    ),
  },
  {
    title: "Гүйлгээний төрөл",
    sorter: true,
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={record?.drOrCr === "Debit" ? "red" : "green"}>
          {record?.drOrCr === "Debit" ? "Зарлага" : "Орлого"}
        </Badge>
      );
    },
  },
  {
    title: "Гүйлгээний утга",
    sorter: true,
    render: (record) => <Text size="sm">{record.tranDesc ?? "-"}</Text>,
  },
  {
    title: "Харьцсан дансны дугаар",
    sorter: true,
    render: (record) =>
      record.drOrCr === "Credit" ? <Text size="sm">{record?.creditAccountNumber ?? "-"}</Text> : <Text size="sm">{record?.debitAccountNumber ?? "-"}</Text>,
  },
  {
    title: "Харьцсан дансны нэр",
    sorter: true,
    render: (record) =>
      record.drOrCr === "Credit" ? <Text size="sm">{record?.creditAccountName ?? "-"}</Text> : <Text size="sm">{record?.debitAccountName ?? "-"}</Text>,
  },
  {
    title: "Харьцсан банк",
    sorter: true,
    render: (record) =>
      record.drOrCr === "Credit" ? <Text size="sm">{record?.creditAccountBank ?? "-"}</Text> : <Text size="sm">{record?.debitAccountBank ?? "-"}</Text>,
  },
  {
    title: "Гүйлгээ хийсэн",
    sorter: true,
    render: (record) => {
      if (record?.drOrCr === "Credit" && record?.payerUser)
        return (
          <Flex gap={10} align="center">
            <Avatar src={record?.payerUser?.avatar} />
            <Text size="sm">
              {record?.payerUser?.lastName ? record?.payerUser?.lastName[0] + "." : "-"} {record?.payerUser?.firstName ?? "-"}
            </Text>
          </Flex>
        );
      else
        return (
          <Flex gap={8} align="center">
            -
          </Flex>
        );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "#",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Төлбөр тооцоо",
  },
  {
    label: "Дансны хуулга",
    to: "/statement",
  },
  {
    label: "Дансны хуулга",
  },
];

const useStyle = createStyles(() => ({}));
