import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: { name: string; logo: string }) => {
  return httpRequest.post("/pay/brand", data);
};

export const select = async () => {
  return httpRequest.get("/pay/brand/select");
};
