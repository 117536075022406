import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { AuthApi, GeneralApi } from "./apis";
import { Layout } from "./components/layout";
import { IAuth } from "./interfaces/IAuth";
import { Access } from "./pages/access";
import { AccountList } from "./pages/account-list";
import { Dashboard } from "./pages/dashboard/dashboard";
import { HistoryDetails } from "./pages/history/details";
import { HistoryList } from "./pages/history/list";
import { NotFound } from "./pages/not-found";
import { StatementDetails } from "./pages/statement/details";
import { StatementList } from "./pages/statement/list";
import { authMe } from "./store/Auth";
import { initGeneral } from "./store/General";
import { message } from "./utils/Message";

let __WINDOW_LOADED__ = false;

function App() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state: { auth: IAuth }) => state.auth);

  const init = React.useCallback(async () => {
    setLoading(true);
    (async () => {
      try {
        if (accessToken) {
          const auth = await AuthApi.me();
          dispatch(authMe(auth));
          const res = await GeneralApi.init();
          dispatch(initGeneral(res));
        } else __WINDOW_LOADED__ = false;
        setLoading(false);
      } catch (error: any) {
        message.error(error?.message);
        setLoading(false);
      }
    })();
    setLoading(false);
  }, [accessToken, dispatch]);

  React.useEffect(() => {
    if (!__WINDOW_LOADED__) init();

    __WINDOW_LOADED__ = true;
  }, [init]);

  if (loading && !__WINDOW_LOADED__) return <LoadingOverlay visible={loading} />;

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/accounts" element={<AccountList />} />

        <Route path="/statement" element={<StatementList />} />
        <Route path="/statement/:id" element={<StatementDetails />} />

        <Route path="/history" element={<HistoryList />} />
        <Route path="/history/:id" element={<HistoryDetails />} />
        <Route path="/access" element={<Access />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
