import { Button, Container, Image, SimpleGrid, Text, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import image from "../../assets/image.svg";
import classes from "./NotFound.module.css";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container className={classes.root}>
      <SimpleGrid spacing={{ base: 40, sm: 80 }} cols={{ base: 1, sm: 2 }}>
        <Image src={image} className={classes.mobileImage} draggable={false} />
        <div>
          <Title className={classes.title}>Таны хайсан хуудас олдсонгүй ...</Title>
          <Text c="dimmed" size="lg">
            Таны нээх гэж байгаа хуудас байхгүй байна. Та хаягаа буруу оруулсан эсвэл хуудсыг өөр URL руу зөөсөн байж магадгүй.
          </Text>
          <Button variant="outline" size="md" mt="xl" className={classes.control} onClick={() => navigate("/")}>
            Нүүр хуудас
          </Button>
        </div>
        <Image src={image} className={classes.desktopImage} draggable={false} />
      </SimpleGrid>
    </Container>
  );
};

export { NotFound };
