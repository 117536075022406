import { Checkbox, Input } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  children?: string;
  size?: any;
  onChange?: any;
  readOnly?: boolean;
};

export function CheckboxField({ name, label, disabled = false, required = false, children, size, onChange: onChangeCustom, readOnly }: Props) {
  const { value, error, onChange } = useField(name);
  const { classes } = useStyle();
  return (
    <Input.Wrapper label={label} required={required} error={error}>
      <div className={classes.row}>
        <Checkbox
          size={size}
          disabled={disabled}
          checked={value}
          onChange={(e) => {
            if (!readOnly) {
              onChange(e.currentTarget.checked);
              onChangeCustom && onChangeCustom(e);
            }
          }}
          label={children}></Checkbox>
      </div>
    </Input.Wrapper>
  );
}

const useStyle = createStyles(() => ({
  row: {
    height: "35px",
    display: "flex",
    alignItems: "center",
    padding: 0,
  },
}));
